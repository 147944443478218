import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _624d4110 = () => interopDefault(import('../pages/daftar-implementasi-pesantren/index.vue' /* webpackChunkName: "pages/daftar-implementasi-pesantren/index" */))
const _e87a8244 = () => interopDefault(import('../pages/login-history/index.vue' /* webpackChunkName: "pages/login-history/index" */))
const _27d3603b = () => interopDefault(import('../pages/ppob/index.vue' /* webpackChunkName: "pages/ppob/index" */))
const _79133157 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _71dea1d2 = () => interopDefault(import('../pages/read-more.vue' /* webpackChunkName: "pages/read-more" */))
const _723598f3 = () => interopDefault(import('../pages/santri/index.vue' /* webpackChunkName: "pages/santri/index" */))
const _2a580fc4 = () => interopDefault(import('../pages/admin/home.vue' /* webpackChunkName: "pages/admin/home" */))
const _01dfbee7 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _5a4cc6d6 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _21476aa9 = () => interopDefault(import('../pages/daftar-implementasi-pesantren/success.vue' /* webpackChunkName: "pages/daftar-implementasi-pesantren/success" */))
const _4d49c9d5 = () => interopDefault(import('../pages/host/midtrans/index.vue' /* webpackChunkName: "pages/host/midtrans/index" */))
const _4708d6d6 = () => interopDefault(import('../pages/host/otto/index.vue' /* webpackChunkName: "pages/host/otto/index" */))
const _39f10226 = () => interopDefault(import('../pages/host/pay.vue' /* webpackChunkName: "pages/host/pay" */))
const _144967a0 = () => interopDefault(import('../pages/login-history/create.vue' /* webpackChunkName: "pages/login-history/create" */))
const _ed6e9d20 = () => interopDefault(import('../pages/page/cek-status-booking/index.vue' /* webpackChunkName: "pages/page/cek-status-booking/index" */))
const _6b58eea0 = () => interopDefault(import('../pages/page/kontak-kami.vue' /* webpackChunkName: "pages/page/kontak-kami" */))
const _dbc24b88 = () => interopDefault(import('../pages/page/peta-situs.vue' /* webpackChunkName: "pages/page/peta-situs" */))
const _ebb7517c = () => interopDefault(import('../pages/page/privacy-policy.vue' /* webpackChunkName: "pages/page/privacy-policy" */))
const _8f43c632 = () => interopDefault(import('../pages/page/tentang-kami.vue' /* webpackChunkName: "pages/page/tentang-kami" */))
const _f03d4e3e = () => interopDefault(import('../pages/ppob/type.vue' /* webpackChunkName: "pages/ppob/type" */))
const _68d4cd6b = () => interopDefault(import('../pages/santri/profil.vue' /* webpackChunkName: "pages/santri/profil" */))
const _4d70b8ce = () => interopDefault(import('../pages/admin/core/akun-keuangan/index.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/index" */))
const _5710b60f = () => interopDefault(import('../pages/admin/core/buku-besar/index.vue' /* webpackChunkName: "pages/admin/core/buku-besar/index" */))
const _6d7d2a8c = () => interopDefault(import('../pages/admin/core/jurnal-khusus/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-khusus/index" */))
const _52f942df = () => interopDefault(import('../pages/admin/core/jurnal-penyesuaian/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-penyesuaian/index" */))
const _51779263 = () => interopDefault(import('../pages/admin/core/jurnal-umum/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-umum/index" */))
const _7ddab024 = () => interopDefault(import('../pages/admin/core/kertas-kerja/index.vue' /* webpackChunkName: "pages/admin/core/kertas-kerja/index" */))
const _249fe516 = () => interopDefault(import('../pages/admin/core/laba-rugi/index.vue' /* webpackChunkName: "pages/admin/core/laba-rugi/index" */))
const _018933cc = () => interopDefault(import('../pages/admin/core/laporan-keuangan/index.vue' /* webpackChunkName: "pages/admin/core/laporan-keuangan/index" */))
const _bae33e3c = () => interopDefault(import('../pages/admin/core/neraca/index.vue' /* webpackChunkName: "pages/admin/core/neraca/index" */))
const _859fd7d4 = () => interopDefault(import('../pages/admin/customer-service/blast-wa/index.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/index" */))
const _67c6a5c7 = () => interopDefault(import('../pages/admin/customer-service/nasabah/index.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/index" */))
const _52373edc = () => interopDefault(import('../pages/admin/customer-service/registrasi/index.vue' /* webpackChunkName: "pages/admin/customer-service/registrasi/index" */))
const _a437c674 = () => interopDefault(import('../pages/admin/customer-service/rekening-koran/index.vue' /* webpackChunkName: "pages/admin/customer-service/rekening-koran/index" */))
const _b4d9fa1c = () => interopDefault(import('../pages/admin/customer-service/rekening-wali/index.vue' /* webpackChunkName: "pages/admin/customer-service/rekening-wali/index" */))
const _b7783078 = () => interopDefault(import('../pages/admin/customer-service/riwayat-transfer/index.vue' /* webpackChunkName: "pages/admin/customer-service/riwayat-transfer/index" */))
const _f3d36daa = () => interopDefault(import('../pages/admin/customer-service/tagihan-spp/index.vue' /* webpackChunkName: "pages/admin/customer-service/tagihan-spp/index" */))
const _9da74372 = () => interopDefault(import('../pages/admin/customer-service/transaksi/index.vue' /* webpackChunkName: "pages/admin/customer-service/transaksi/index" */))
const _7bb8171e = () => interopDefault(import('../pages/admin/customer-service/wali/index.vue' /* webpackChunkName: "pages/admin/customer-service/wali/index" */))
const _656d3ef7 = () => interopDefault(import('../pages/admin/master/bank/index.vue' /* webpackChunkName: "pages/admin/master/bank/index" */))
const _6fa34712 = () => interopDefault(import('../pages/admin/master/bcn-bank/index.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/index" */))
const _b46ebfb2 = () => interopDefault(import('../pages/admin/master/document-type/index.vue' /* webpackChunkName: "pages/admin/master/document-type/index" */))
const _1c80cd48 = () => interopDefault(import('../pages/admin/master/jenis-kas/index.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/index" */))
const _cf29094e = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/index.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/index" */))
const _ee367f68 = () => interopDefault(import('../pages/admin/master/jenis-rekening/index.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/index" */))
const _0e36c7de = () => interopDefault(import('../pages/admin/master/jenis-simpanan/index.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/index" */))
const _379e8fa8 = () => interopDefault(import('../pages/admin/master/lembaga/index.vue' /* webpackChunkName: "pages/admin/master/lembaga/index" */))
const _49ff9f34 = () => interopDefault(import('../pages/admin/master/lembaga-booking/index.vue' /* webpackChunkName: "pages/admin/master/lembaga-booking/index" */))
const _bb799a64 = () => interopDefault(import('../pages/admin/master/news/index.vue' /* webpackChunkName: "pages/admin/master/news/index" */))
const _0a9d499a = () => interopDefault(import('../pages/admin/master/news-category/index.vue' /* webpackChunkName: "pages/admin/master/news-category/index" */))
const _5752cfa8 = () => interopDefault(import('../pages/admin/master/produk/index.vue' /* webpackChunkName: "pages/admin/master/produk/index" */))
const _10177314 = () => interopDefault(import('../pages/admin/master/setting-institution/index.vue' /* webpackChunkName: "pages/admin/master/setting-institution/index" */))
const _45283544 = () => interopDefault(import('../pages/admin/master/transaction-type/index.vue' /* webpackChunkName: "pages/admin/master/transaction-type/index" */))
const _60ba1f59 = () => interopDefault(import('../pages/admin/pengaturan/management-user/index.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/index" */))
const _57393121 = () => interopDefault(import('../pages/admin/pengaturan/permission/index.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/index" */))
const _637b6770 = () => interopDefault(import('../pages/admin/pengaturan/role/index.vue' /* webpackChunkName: "pages/admin/pengaturan/role/index" */))
const _3e9d93dd = () => interopDefault(import('../pages/admin/pengaturan/role-has-permission/index.vue' /* webpackChunkName: "pages/admin/pengaturan/role-has-permission/index" */))
const _9eb0338e = () => interopDefault(import('../pages/admin/transaksi/monitoring-admin/index.vue' /* webpackChunkName: "pages/admin/transaksi/monitoring-admin/index" */))
const _413809c0 = () => interopDefault(import('../pages/admin/transaksi/penarikan/index.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan/index" */))
const _56ad4543 = () => interopDefault(import('../pages/admin/transaksi/penarikan-otomatis/index.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan-otomatis/index" */))
const _12bd9721 = () => interopDefault(import('../pages/admin/transaksi/setoran-tunai/index.vue' /* webpackChunkName: "pages/admin/transaksi/setoran-tunai/index" */))
const _5f506930 = () => interopDefault(import('../pages/host/flip/accept.vue' /* webpackChunkName: "pages/host/flip/accept" */))
const _638bd2cb = () => interopDefault(import('../pages/host/flip/transfer.vue' /* webpackChunkName: "pages/host/flip/transfer" */))
const _ef4aa8a0 = () => interopDefault(import('../pages/admin/core/akun-keuangan/create.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/create" */))
const _052f04bb = () => interopDefault(import('../pages/admin/core/jurnal-umum/create.vue' /* webpackChunkName: "pages/admin/core/jurnal-umum/create" */))
const _132612e9 = () => interopDefault(import('../pages/admin/customer-service/blast-wa/template/index.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/template/index" */))
const _8e7b4452 = () => interopDefault(import('../pages/admin/customer-service/nasabah/create.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/create" */))
const _739db48f = () => interopDefault(import('../pages/admin/customer-service/nasabah/export.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/export" */))
const _59576c08 = () => interopDefault(import('../pages/admin/customer-service/nasabah/qr.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/qr" */))
const _7be8141f = () => interopDefault(import('../pages/admin/customer-service/nasabah/rekening/index.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/rekening/index" */))
const _58a8c05c = () => interopDefault(import('../pages/admin/customer-service/nasabah/upload.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/upload" */))
const _2d5bfc6c = () => interopDefault(import('../pages/admin/customer-service/registrasi/create.vue' /* webpackChunkName: "pages/admin/customer-service/registrasi/create" */))
const _22ff1760 = () => interopDefault(import('../pages/admin/customer-service/wali/create.vue' /* webpackChunkName: "pages/admin/customer-service/wali/create" */))
const _920996b0 = () => interopDefault(import('../pages/admin/customer-service/wali/rekening/index.vue' /* webpackChunkName: "pages/admin/customer-service/wali/rekening/index" */))
const _6feeeaa7 = () => interopDefault(import('../pages/admin/master/bank/create.vue' /* webpackChunkName: "pages/admin/master/bank/create" */))
const _65517d27 = () => interopDefault(import('../pages/admin/master/bcn-bank/create.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/create" */))
const _3affaf77 = () => interopDefault(import('../pages/admin/master/document-type/create.vue' /* webpackChunkName: "pages/admin/master/document-type/create" */))
const _c965b314 = () => interopDefault(import('../pages/admin/master/jenis-kas/create.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/create" */))
const _c68f8af6 = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/create.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/create" */))
const _8930d81c = () => interopDefault(import('../pages/admin/master/jenis-rekening/create.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/create" */))
const _3f5302c0 = () => interopDefault(import('../pages/admin/master/jenis-simpanan/create.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/create" */))
const _cef0526c = () => interopDefault(import('../pages/admin/master/lembaga/bcn-code/index.vue' /* webpackChunkName: "pages/admin/master/lembaga/bcn-code/index" */))
const _38309fd4 = () => interopDefault(import('../pages/admin/master/lembaga/create.vue' /* webpackChunkName: "pages/admin/master/lembaga/create" */))
const _f9a3542a = () => interopDefault(import('../pages/admin/master/news-category/create.vue' /* webpackChunkName: "pages/admin/master/news-category/create" */))
const _64511ca0 = () => interopDefault(import('../pages/admin/master/news/create.vue' /* webpackChunkName: "pages/admin/master/news/create" */))
const _8a891fd4 = () => interopDefault(import('../pages/admin/master/produk/create.vue' /* webpackChunkName: "pages/admin/master/produk/create" */))
const _f0da840c = () => interopDefault(import('../pages/admin/master/transaction-type/create.vue' /* webpackChunkName: "pages/admin/master/transaction-type/create" */))
const _4383d2f6 = () => interopDefault(import('../pages/admin/pengaturan/management-user/create.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/create" */))
const _90bd8486 = () => interopDefault(import('../pages/admin/pengaturan/permission/create.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/create" */))
const _cada4176 = () => interopDefault(import('../pages/admin/pengaturan/ppob/product/index.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product/index" */))
const _012df8ef = () => interopDefault(import('../pages/admin/pengaturan/ppob/product-detail/index.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product-detail/index" */))
const _d9d11550 = () => interopDefault(import('../pages/admin/pengaturan/ppob/product-type/index.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product-type/index" */))
const _21bb8776 = () => interopDefault(import('../pages/admin/pengaturan/role/create.vue' /* webpackChunkName: "pages/admin/pengaturan/role/create" */))
const _b2a0a44a = () => interopDefault(import('../pages/admin/transaksi/penarikan-otomatis/create.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan-otomatis/create" */))
const _34cfb39e = () => interopDefault(import('../pages/admin/transaksi/penarikan/create.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan/create" */))
const _6ca997bd = () => interopDefault(import('../pages/admin/transaksi/setoran-tunai/create.vue' /* webpackChunkName: "pages/admin/transaksi/setoran-tunai/create" */))
const _795094f5 = () => interopDefault(import('../pages/admin/customer-service/blast-wa/template/create.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/template/create" */))
const _4c456faf = () => interopDefault(import('../pages/admin/pengaturan/ppob/product-detail/create.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product-detail/create" */))
const _10e8ff34 = () => interopDefault(import('../pages/admin/pengaturan/ppob/product-type/create.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product-type/create" */))
const _410557ce = () => interopDefault(import('../pages/admin/pengaturan/ppob/product/create.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product/create" */))
const _2b20a53d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _488488de = () => interopDefault(import('../pages/admin/customer-service/blast-wa/template/_id.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/template/_id" */))
const _32edf245 = () => interopDefault(import('../pages/admin/master/produk/custom-nominal/_id.vue' /* webpackChunkName: "pages/admin/master/produk/custom-nominal/_id" */))
const _20736b17 = () => interopDefault(import('../pages/admin/pengaturan/ppob/product-detail/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product-detail/_id" */))
const _b79a2480 = () => interopDefault(import('../pages/admin/pengaturan/ppob/product-type/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product-type/_id" */))
const _1d69eeed = () => interopDefault(import('../pages/admin/pengaturan/ppob/product/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/ppob/product/_id" */))
const _705ad294 = () => interopDefault(import('../pages/admin/core/akun-keuangan/_id.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/_id" */))
const _5afd2422 = () => interopDefault(import('../pages/admin/customer-service/nasabah/_id.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/_id" */))
const _54056df4 = () => interopDefault(import('../pages/admin/customer-service/wali/_id.vue' /* webpackChunkName: "pages/admin/customer-service/wali/_id" */))
const _5a5c931f = () => interopDefault(import('../pages/admin/master/bank/_id.vue' /* webpackChunkName: "pages/admin/master/bank/_id" */))
const _2471dec2 = () => interopDefault(import('../pages/admin/master/bcn-bank/_id.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/_id" */))
const _5b7e804f = () => interopDefault(import('../pages/admin/master/document-type/_id.vue' /* webpackChunkName: "pages/admin/master/document-type/_id" */))
const _3a1cfca0 = () => interopDefault(import('../pages/admin/master/jenis-kas/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/_id" */))
const _b1bafffe = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/_id" */))
const _1efe65b4 = () => interopDefault(import('../pages/admin/master/jenis-rekening/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/_id" */))
const _d41dac74 = () => interopDefault(import('../pages/admin/master/jenis-simpanan/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/_id" */))
const _254747e0 = () => interopDefault(import('../pages/admin/master/lembaga/_id.vue' /* webpackChunkName: "pages/admin/master/lembaga/_id" */))
const _4d328e5b = () => interopDefault(import('../pages/admin/master/news-category/_id.vue' /* webpackChunkName: "pages/admin/master/news-category/_id" */))
const _42fb90b6 = () => interopDefault(import('../pages/admin/master/news/_id.vue' /* webpackChunkName: "pages/admin/master/news/_id" */))
const _25bec7e0 = () => interopDefault(import('../pages/admin/master/produk/_id.vue' /* webpackChunkName: "pages/admin/master/produk/_id" */))
const _7dad6e5e = () => interopDefault(import('../pages/admin/master/setting-institution/_id.vue' /* webpackChunkName: "pages/admin/master/setting-institution/_id" */))
const _2d0ab8ac = () => interopDefault(import('../pages/admin/master/transaction-type/_id.vue' /* webpackChunkName: "pages/admin/master/transaction-type/_id" */))
const _8a6bb7fe = () => interopDefault(import('../pages/admin/pengaturan/management-user/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/_id" */))
const _57f307c9 = () => interopDefault(import('../pages/admin/pengaturan/permission/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/_id" */))
const _3d48e985 = () => interopDefault(import('../pages/admin/pengaturan/role-has-permission/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/role-has-permission/_id" */))
const _75f27ea0 = () => interopDefault(import('../pages/admin/pengaturan/role/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/role/_id" */))
const _55976050 = () => interopDefault(import('../pages/page/cek-status-booking/_id.vue' /* webpackChunkName: "pages/page/cek-status-booking/_id" */))
const _2cc3a21c = () => interopDefault(import('../pages/auth/_registerBak.vue' /* webpackChunkName: "pages/auth/_registerBak" */))
const _240ee0c6 = () => interopDefault(import('../pages/login-history/_id.vue' /* webpackChunkName: "pages/login-history/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/daftar-implementasi-pesantren",
    component: _624d4110,
    name: "daftar-implementasi-pesantren"
  }, {
    path: "/login-history",
    component: _e87a8244,
    name: "login-history"
  }, {
    path: "/ppob",
    component: _27d3603b,
    name: "ppob"
  }, {
    path: "/profile",
    component: _79133157,
    name: "profile"
  }, {
    path: "/read-more",
    component: _71dea1d2,
    name: "read-more"
  }, {
    path: "/santri",
    component: _723598f3,
    name: "santri"
  }, {
    path: "/admin/home",
    component: _2a580fc4,
    name: "admin-home"
  }, {
    path: "/auth/login",
    component: _01dfbee7,
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _5a4cc6d6,
    name: "auth-register"
  }, {
    path: "/daftar-implementasi-pesantren/success",
    component: _21476aa9,
    name: "daftar-implementasi-pesantren-success"
  }, {
    path: "/host/midtrans",
    component: _4d49c9d5,
    name: "host-midtrans"
  }, {
    path: "/host/otto",
    component: _4708d6d6,
    name: "host-otto"
  }, {
    path: "/host/pay",
    component: _39f10226,
    name: "host-pay"
  }, {
    path: "/login-history/create",
    component: _144967a0,
    name: "login-history-create"
  }, {
    path: "/page/cek-status-booking",
    component: _ed6e9d20,
    name: "page-cek-status-booking"
  }, {
    path: "/page/kontak-kami",
    component: _6b58eea0,
    name: "page-kontak-kami"
  }, {
    path: "/page/peta-situs",
    component: _dbc24b88,
    name: "page-peta-situs"
  }, {
    path: "/page/privacy-policy",
    component: _ebb7517c,
    name: "page-privacy-policy"
  }, {
    path: "/page/tentang-kami",
    component: _8f43c632,
    name: "page-tentang-kami"
  }, {
    path: "/ppob/type",
    component: _f03d4e3e,
    name: "ppob-type"
  }, {
    path: "/santri/profil",
    component: _68d4cd6b,
    name: "santri-profil"
  }, {
    path: "/admin/core/akun-keuangan",
    component: _4d70b8ce,
    name: "admin-core-akun-keuangan"
  }, {
    path: "/admin/core/buku-besar",
    component: _5710b60f,
    name: "admin-core-buku-besar"
  }, {
    path: "/admin/core/jurnal-khusus",
    component: _6d7d2a8c,
    name: "admin-core-jurnal-khusus"
  }, {
    path: "/admin/core/jurnal-penyesuaian",
    component: _52f942df,
    name: "admin-core-jurnal-penyesuaian"
  }, {
    path: "/admin/core/jurnal-umum",
    component: _51779263,
    name: "admin-core-jurnal-umum"
  }, {
    path: "/admin/core/kertas-kerja",
    component: _7ddab024,
    name: "admin-core-kertas-kerja"
  }, {
    path: "/admin/core/laba-rugi",
    component: _249fe516,
    name: "admin-core-laba-rugi"
  }, {
    path: "/admin/core/laporan-keuangan",
    component: _018933cc,
    name: "admin-core-laporan-keuangan"
  }, {
    path: "/admin/core/neraca",
    component: _bae33e3c,
    name: "admin-core-neraca"
  }, {
    path: "/admin/customer-service/blast-wa",
    component: _859fd7d4,
    name: "admin-customer-service-blast-wa"
  }, {
    path: "/admin/customer-service/nasabah",
    component: _67c6a5c7,
    name: "admin-customer-service-nasabah"
  }, {
    path: "/admin/customer-service/registrasi",
    component: _52373edc,
    name: "admin-customer-service-registrasi"
  }, {
    path: "/admin/customer-service/rekening-koran",
    component: _a437c674,
    name: "admin-customer-service-rekening-koran"
  }, {
    path: "/admin/customer-service/rekening-wali",
    component: _b4d9fa1c,
    name: "admin-customer-service-rekening-wali"
  }, {
    path: "/admin/customer-service/riwayat-transfer",
    component: _b7783078,
    name: "admin-customer-service-riwayat-transfer"
  }, {
    path: "/admin/customer-service/tagihan-spp",
    component: _f3d36daa,
    name: "admin-customer-service-tagihan-spp"
  }, {
    path: "/admin/customer-service/transaksi",
    component: _9da74372,
    name: "admin-customer-service-transaksi"
  }, {
    path: "/admin/customer-service/wali",
    component: _7bb8171e,
    name: "admin-customer-service-wali"
  }, {
    path: "/admin/master/bank",
    component: _656d3ef7,
    name: "admin-master-bank"
  }, {
    path: "/admin/master/bcn-bank",
    component: _6fa34712,
    name: "admin-master-bcn-bank"
  }, {
    path: "/admin/master/document-type",
    component: _b46ebfb2,
    name: "admin-master-document-type"
  }, {
    path: "/admin/master/jenis-kas",
    component: _1c80cd48,
    name: "admin-master-jenis-kas"
  }, {
    path: "/admin/master/jenis-pinjaman",
    component: _cf29094e,
    name: "admin-master-jenis-pinjaman"
  }, {
    path: "/admin/master/jenis-rekening",
    component: _ee367f68,
    name: "admin-master-jenis-rekening"
  }, {
    path: "/admin/master/jenis-simpanan",
    component: _0e36c7de,
    name: "admin-master-jenis-simpanan"
  }, {
    path: "/admin/master/lembaga",
    component: _379e8fa8,
    name: "admin-master-lembaga"
  }, {
    path: "/admin/master/lembaga-booking",
    component: _49ff9f34,
    name: "admin-master-lembaga-booking"
  }, {
    path: "/admin/master/news",
    component: _bb799a64,
    name: "admin-master-news"
  }, {
    path: "/admin/master/news-category",
    component: _0a9d499a,
    name: "admin-master-news-category"
  }, {
    path: "/admin/master/produk",
    component: _5752cfa8,
    name: "admin-master-produk"
  }, {
    path: "/admin/master/setting-institution",
    component: _10177314,
    name: "admin-master-setting-institution"
  }, {
    path: "/admin/master/transaction-type",
    component: _45283544,
    name: "admin-master-transaction-type"
  }, {
    path: "/admin/pengaturan/management-user",
    component: _60ba1f59,
    name: "admin-pengaturan-management-user"
  }, {
    path: "/admin/pengaturan/permission",
    component: _57393121,
    name: "admin-pengaturan-permission"
  }, {
    path: "/admin/pengaturan/role",
    component: _637b6770,
    name: "admin-pengaturan-role"
  }, {
    path: "/admin/pengaturan/role-has-permission",
    component: _3e9d93dd,
    name: "admin-pengaturan-role-has-permission"
  }, {
    path: "/admin/transaksi/monitoring-admin",
    component: _9eb0338e,
    name: "admin-transaksi-monitoring-admin"
  }, {
    path: "/admin/transaksi/penarikan",
    component: _413809c0,
    name: "admin-transaksi-penarikan"
  }, {
    path: "/admin/transaksi/penarikan-otomatis",
    component: _56ad4543,
    name: "admin-transaksi-penarikan-otomatis"
  }, {
    path: "/admin/transaksi/setoran-tunai",
    component: _12bd9721,
    name: "admin-transaksi-setoran-tunai"
  }, {
    path: "/host/flip/accept",
    component: _5f506930,
    name: "host-flip-accept"
  }, {
    path: "/host/flip/transfer",
    component: _638bd2cb,
    name: "host-flip-transfer"
  }, {
    path: "/admin/core/akun-keuangan/create",
    component: _ef4aa8a0,
    name: "admin-core-akun-keuangan-create"
  }, {
    path: "/admin/core/jurnal-umum/create",
    component: _052f04bb,
    name: "admin-core-jurnal-umum-create"
  }, {
    path: "/admin/customer-service/blast-wa/template",
    component: _132612e9,
    name: "admin-customer-service-blast-wa-template"
  }, {
    path: "/admin/customer-service/nasabah/create",
    component: _8e7b4452,
    name: "admin-customer-service-nasabah-create"
  }, {
    path: "/admin/customer-service/nasabah/export",
    component: _739db48f,
    name: "admin-customer-service-nasabah-export"
  }, {
    path: "/admin/customer-service/nasabah/qr",
    component: _59576c08,
    name: "admin-customer-service-nasabah-qr"
  }, {
    path: "/admin/customer-service/nasabah/rekening",
    component: _7be8141f,
    name: "admin-customer-service-nasabah-rekening"
  }, {
    path: "/admin/customer-service/nasabah/upload",
    component: _58a8c05c,
    name: "admin-customer-service-nasabah-upload"
  }, {
    path: "/admin/customer-service/registrasi/create",
    component: _2d5bfc6c,
    name: "admin-customer-service-registrasi-create"
  }, {
    path: "/admin/customer-service/wali/create",
    component: _22ff1760,
    name: "admin-customer-service-wali-create"
  }, {
    path: "/admin/customer-service/wali/rekening",
    component: _920996b0,
    name: "admin-customer-service-wali-rekening"
  }, {
    path: "/admin/master/bank/create",
    component: _6feeeaa7,
    name: "admin-master-bank-create"
  }, {
    path: "/admin/master/bcn-bank/create",
    component: _65517d27,
    name: "admin-master-bcn-bank-create"
  }, {
    path: "/admin/master/document-type/create",
    component: _3affaf77,
    name: "admin-master-document-type-create"
  }, {
    path: "/admin/master/jenis-kas/create",
    component: _c965b314,
    name: "admin-master-jenis-kas-create"
  }, {
    path: "/admin/master/jenis-pinjaman/create",
    component: _c68f8af6,
    name: "admin-master-jenis-pinjaman-create"
  }, {
    path: "/admin/master/jenis-rekening/create",
    component: _8930d81c,
    name: "admin-master-jenis-rekening-create"
  }, {
    path: "/admin/master/jenis-simpanan/create",
    component: _3f5302c0,
    name: "admin-master-jenis-simpanan-create"
  }, {
    path: "/admin/master/lembaga/bcn-code",
    component: _cef0526c,
    name: "admin-master-lembaga-bcn-code"
  }, {
    path: "/admin/master/lembaga/create",
    component: _38309fd4,
    name: "admin-master-lembaga-create"
  }, {
    path: "/admin/master/news-category/create",
    component: _f9a3542a,
    name: "admin-master-news-category-create"
  }, {
    path: "/admin/master/news/create",
    component: _64511ca0,
    name: "admin-master-news-create"
  }, {
    path: "/admin/master/produk/create",
    component: _8a891fd4,
    name: "admin-master-produk-create"
  }, {
    path: "/admin/master/transaction-type/create",
    component: _f0da840c,
    name: "admin-master-transaction-type-create"
  }, {
    path: "/admin/pengaturan/management-user/create",
    component: _4383d2f6,
    name: "admin-pengaturan-management-user-create"
  }, {
    path: "/admin/pengaturan/permission/create",
    component: _90bd8486,
    name: "admin-pengaturan-permission-create"
  }, {
    path: "/admin/pengaturan/ppob/product",
    component: _cada4176,
    name: "admin-pengaturan-ppob-product"
  }, {
    path: "/admin/pengaturan/ppob/product-detail",
    component: _012df8ef,
    name: "admin-pengaturan-ppob-product-detail"
  }, {
    path: "/admin/pengaturan/ppob/product-type",
    component: _d9d11550,
    name: "admin-pengaturan-ppob-product-type"
  }, {
    path: "/admin/pengaturan/role/create",
    component: _21bb8776,
    name: "admin-pengaturan-role-create"
  }, {
    path: "/admin/transaksi/penarikan-otomatis/create",
    component: _b2a0a44a,
    name: "admin-transaksi-penarikan-otomatis-create"
  }, {
    path: "/admin/transaksi/penarikan/create",
    component: _34cfb39e,
    name: "admin-transaksi-penarikan-create"
  }, {
    path: "/admin/transaksi/setoran-tunai/create",
    component: _6ca997bd,
    name: "admin-transaksi-setoran-tunai-create"
  }, {
    path: "/admin/customer-service/blast-wa/template/create",
    component: _795094f5,
    name: "admin-customer-service-blast-wa-template-create"
  }, {
    path: "/admin/pengaturan/ppob/product-detail/create",
    component: _4c456faf,
    name: "admin-pengaturan-ppob-product-detail-create"
  }, {
    path: "/admin/pengaturan/ppob/product-type/create",
    component: _10e8ff34,
    name: "admin-pengaturan-ppob-product-type-create"
  }, {
    path: "/admin/pengaturan/ppob/product/create",
    component: _410557ce,
    name: "admin-pengaturan-ppob-product-create"
  }, {
    path: "/",
    component: _2b20a53d,
    name: "index"
  }, {
    path: "/admin/customer-service/blast-wa/template/:id?",
    component: _488488de,
    name: "admin-customer-service-blast-wa-template-id"
  }, {
    path: "/admin/master/produk/custom-nominal/:id?",
    component: _32edf245,
    name: "admin-master-produk-custom-nominal-id"
  }, {
    path: "/admin/pengaturan/ppob/product-detail/:id",
    component: _20736b17,
    name: "admin-pengaturan-ppob-product-detail-id"
  }, {
    path: "/admin/pengaturan/ppob/product-type/:id",
    component: _b79a2480,
    name: "admin-pengaturan-ppob-product-type-id"
  }, {
    path: "/admin/pengaturan/ppob/product/:id",
    component: _1d69eeed,
    name: "admin-pengaturan-ppob-product-id"
  }, {
    path: "/admin/core/akun-keuangan/:id?",
    component: _705ad294,
    name: "admin-core-akun-keuangan-id"
  }, {
    path: "/admin/customer-service/nasabah/:id?",
    component: _5afd2422,
    name: "admin-customer-service-nasabah-id"
  }, {
    path: "/admin/customer-service/wali/:id?",
    component: _54056df4,
    name: "admin-customer-service-wali-id"
  }, {
    path: "/admin/master/bank/:id",
    component: _5a5c931f,
    name: "admin-master-bank-id"
  }, {
    path: "/admin/master/bcn-bank/:id?",
    component: _2471dec2,
    name: "admin-master-bcn-bank-id"
  }, {
    path: "/admin/master/document-type/:id?",
    component: _5b7e804f,
    name: "admin-master-document-type-id"
  }, {
    path: "/admin/master/jenis-kas/:id?",
    component: _3a1cfca0,
    name: "admin-master-jenis-kas-id"
  }, {
    path: "/admin/master/jenis-pinjaman/:id?",
    component: _b1bafffe,
    name: "admin-master-jenis-pinjaman-id"
  }, {
    path: "/admin/master/jenis-rekening/:id?",
    component: _1efe65b4,
    name: "admin-master-jenis-rekening-id"
  }, {
    path: "/admin/master/jenis-simpanan/:id?",
    component: _d41dac74,
    name: "admin-master-jenis-simpanan-id"
  }, {
    path: "/admin/master/lembaga/:id",
    component: _254747e0,
    name: "admin-master-lembaga-id"
  }, {
    path: "/admin/master/news-category/:id",
    component: _4d328e5b,
    name: "admin-master-news-category-id"
  }, {
    path: "/admin/master/news/:id",
    component: _42fb90b6,
    name: "admin-master-news-id"
  }, {
    path: "/admin/master/produk/:id",
    component: _25bec7e0,
    name: "admin-master-produk-id"
  }, {
    path: "/admin/master/setting-institution/:id?",
    component: _7dad6e5e,
    name: "admin-master-setting-institution-id"
  }, {
    path: "/admin/master/transaction-type/:id?",
    component: _2d0ab8ac,
    name: "admin-master-transaction-type-id"
  }, {
    path: "/admin/pengaturan/management-user/:id?",
    component: _8a6bb7fe,
    name: "admin-pengaturan-management-user-id"
  }, {
    path: "/admin/pengaturan/permission/:id",
    component: _57f307c9,
    name: "admin-pengaturan-permission-id"
  }, {
    path: "/admin/pengaturan/role-has-permission/:id",
    component: _3d48e985,
    name: "admin-pengaturan-role-has-permission-id"
  }, {
    path: "/admin/pengaturan/role/:id",
    component: _75f27ea0,
    name: "admin-pengaturan-role-id"
  }, {
    path: "/page/cek-status-booking/:id?",
    component: _55976050,
    name: "page-cek-status-booking-id"
  }, {
    path: "/auth/:registerBak?",
    component: _2cc3a21c,
    name: "auth-registerBak"
  }, {
    path: "/login-history/:id?",
    component: _240ee0c6,
    name: "login-history-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
